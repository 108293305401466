export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOAD_FAILED = "LOAD_FAILED";
export const GET_BATCHES = "GET_BATCHES";
export const LOADING = "LOADING";
export const CLEAR_BATCHES = "CLEAR_BATCHES";
export const FAILED = "FAILED";
