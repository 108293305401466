import React, { useEffect, useState, useRef } from "react";
import Parser from "html-react-parser";
import call from "../../../../utils/call";
import check from "../../../../images/icons/check.png";
import mute from "../../../../images/icons/mute.png";

const apiURL = process.env.REACT_APP_EMAILER_SERVER;

const EmailPopup = ({ batch, set_batch, auto_send = false }) => {
  const [page, set_page] = useState(0);
  const [total_pages, set_total_pages] = useState(0);
  const [email, set_email] = useState(null);
  const [sending, set_sending] = useState(false);

  const email_ref = useRef([]);

  const get_email = async (page = 0) => {
    const { email, meta } = await call(
      `${apiURL}api/v2/email/${batch.id}?page=${page}`,
      "get"
    );
    set_email(email[0]);
    set_total_pages(meta.total_pages);
    console.log(email[0]);
    set_page(meta.page - 1);
  };

  useEffect(() => {
    get_email();
  }, []);

  useEffect(() => {
    console.log(email);
    console.log(auto_send);
    if (auto_send && !email_ref.current.includes(email.id)) {
      email_ref.current.push(email.id);
      send_email();
    }
  }, [email]);

  const update_email_status = async (status) => {
    try {
      await call(`${apiURL}api/v2/email/${email.id}`, "patch", {
        status,
      });
      set_email({ ...email, [status]: true });
    } catch (error) {
      console.log(error);
    }
  };

  const send_email = async () => {
    console.log("send email");
    set_sending(true);
    if (email && !email.resolved && !email.sent && !email.ignored) {
      console.log("sending");
      try {
        console.log(email.id);
        const res = await call(`${apiURL}api/v1/email/${email.id}`, "post", {
          status: "send",
        });
        // set_email({ ...email, sent: true });
        set_sending(false);
        if (res.status === "success") {
          set_email({ ...email, sent: true });
        }
      } catch (error) {}
    }

    console.log(page, total_pages, auto_send);
    if (page < total_pages - 1 && auto_send) {
      console.log("getting_email");
      await get_email(page + 1);
    }
  };

  return (
    <div className="email_popup" style={{}}>
      <div
        className="email_popup__overlay"
        onClick={() => set_batch(null)}
      ></div>
      <div className="email_popup__content" style={{ position: "relative" }}>
        {sending ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255,255,255,0.5)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/fe_gif.gif"
              alt="sending"
              style={{
                display: "block",
                backgroundColor: "white",
                borderRadius: "10px",
                border: "1px solid black",
                padding: "5px",
                height: "50%",
                position: "absolute",
                top: "60%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }}
            />
          </div>
        ) : (
          ""
        )}
        <div className="email_popup__content__header">
          <h2 className="email_popup__content__header__title">{batch.name}</h2>
        </div>
        {email && email.html ? (
          <div className="email_popup__content__body">
            <div className="email_popup__content__body__menu">
              {!email.resolved && !email.sent && !email.ignored ? (
                <div className="email_popup__content__body__actions">
                  <img
                    src={check}
                    alt="resolve"
                    title="Resolve"
                    onClick={() => {
                      update_email_status("resolved");
                    }}
                  />
                  <img
                    src={mute}
                    alt="ignore"
                    title="Ignore"
                    onClick={() => {
                      update_email_status("ignored");
                    }}
                  />
                </div>
              ) : (
                ""
              )}
              <div className="email_popup__content__body__menu__page_nav">
                <span
                  className="email_popup__content__body__menu__page_nav__arrow"
                  onClick={() => {
                    if (page > 0) {
                      get_email(page - 1);
                    } else {
                      get_email(total_pages - 1);
                    }
                  }}
                >
                  ←
                </span>
                <span className="email_popup__content__body__menu__page_nav__page_num">
                  {page + 1} of {total_pages}
                </span>
                <span
                  className="email_popup__content__body__menu__page_nav__arrow"
                  onClick={() => {
                    if (page < total_pages - 1) {
                      get_email(page + 1);
                    } else {
                      get_email(0);
                    }
                  }}
                >
                  →
                </span>
                <span
                  className={`email_popup__content__body__menu__action__item 
                      email_popup__content__body__menu__action__item__${
                        email.resolved
                          ? "resolved"
                          : email.sent
                          ? "sent"
                          : email.ignored
                          ? "ignored"
                          : "sendable"
                      }`}
                  onClick={() => {
                    if (
                      !email.resolved &&
                      !email.sent &&
                      !email.ignored &&
                      !email_ref.current.includes(email.id)
                    ) {
                      email_ref.current.push(email.id);
                      send_email();
                    }
                  }}
                >
                  {email.resolved
                    ? "Resolved"
                    : email.sent
                    ? "Sent"
                    : email.ignored
                    ? "Ignored"
                    : "Send"}
                </span>
              </div>
            </div>
            {Parser(email.html)}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default EmailPopup;
