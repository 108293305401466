import React, { useEffect, useState } from "react";
import briefcase from "../../images/svg/briefcase.svg";
import students from "../../images/svg/students.svg";
import right_arrows from "../../images/svg/right-arrows.svg";
import assembly_line from "../../images/svg/assembly-line.svg";

const LeftNav = ({ left_nav, set_left_nav, set_page }) => {
  const [text_appear, set_text_appear] = useState(left_nav);

  useEffect(() => {
    if (left_nav) {
      setTimeout(() => {
        set_text_appear(true);
      }, 200);
    } else {
      set_text_appear(false);
    }
  }, [left_nav]);

  return (
    <div className="left-nav">
      <div
        className="left-nav__hamburger"
        onClick={() => {
          set_left_nav(!left_nav);
        }}
      >
        <img
          src={right_arrows}
          alt="right arrows"
          className={`left-nav__hamburger__bars ${
            left_nav ? "left-nav__hamburger__bars__left" : ""
          }`}
        />
      </div>
      <nav
        className={`left-nav__nav ${
          left_nav ? "" : "left-nav__nav__collapsed"
        }`}
      >
        <ul
          className={`left-nav__nav__list ${
            left_nav ? "" : "left-nav__nav__list__collapsed"
          }`}
        >
          <li
            className={`left-nav__nav__list__item ${
              left_nav
                ? "left-nav__nav__list__item__expanded"
                : "left-nav__nav__list__item__collapsed"
            }`}
            onClick={() => set_page("E-Mailer")}
          >
            <img
              src={briefcase}
              alt="briefcase"
              className="left-nav__nav__list__item__icon"
            />
            {text_appear && left_nav ? (
              <span className="left-nav__nav__list__item__text">E-Mailer</span>
            ) : null}
          </li>
          <li
            className={`left-nav__nav__list__item ${
              left_nav
                ? "left-nav__nav__list__item__expanded"
                : "left-nav__nav__list__item__collapsed"
            }`}
            onClick={() => set_page("Recipient Statistics")}
          >
            <img
              src={students}
              alt="students"
              className="left-nav__nav__list__item__icon"
            />
            {text_appear && left_nav ? (
              <span className="left-nav__nav__list__item__text">
                Recipient Statistics
              </span>
            ) : null}
          </li>
          <li
            className={`left-nav__nav__list__item ${
              left_nav
                ? "left-nav__nav__list__item__expanded"
                : "left-nav__nav__list__item__collapsed"
            }`}
            onClick={() => set_page("Queue")}
          >
            <img
              src={assembly_line}
              alt="students"
              className="left-nav__nav__list__item__icon"
            />
            {text_appear && left_nav ? (
              <span className="left-nav__nav__list__item__text">Queue</span>
            ) : null}
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default LeftNav;
