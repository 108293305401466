import React, { useReducer } from "react";
import { BatchContext } from "./BatchContext";
import BatchReducer from "./BatchReducer";
import call from "../../utils/call";

import { GET_BATCHES, LOADING, CLEAR_BATCHES, FAILED } from "../types";

const apiURL = process.env.REACT_APP_EMAILER_SERVER;

const BatchState = (props) => {
  const initialState = {
    batches: [],
    loading: true,
    page: 0,
    total_pages: 0,
    error: null,
  };

  const [state, dispatch] = useReducer(BatchReducer, initialState);

  // Get Batches
  const getBatches = async (resolved = false, page = 0) => {
    try {
      const res = await call(
        `${apiURL}api/v2/batch?resolved=${resolved}&page=${page}`,
        "GET"
      );
      console.log(res);
      dispatch({ type: GET_BATCHES, payload: res });
    } catch (err) {
      console.error(err);
      dispatch({ type: FAILED, payload: err.response.data.msg });
    }
  };

  // Clear Batches
  const clearBatches = () => {
    dispatch({ type: CLEAR_BATCHES });
  };

  const setLoading = () => {
    dispatch({ type: LOADING });
  };

  return (
    <BatchContext.Provider
      value={{
        batches: state.batches,
        loading: state.loading,
        page: state.page,
        total_pages: state.total_pages,
        error: state.error,
        getBatches,
        clearBatches,
        setLoading,
      }}
    >
      {props.children}
    </BatchContext.Provider>
  );
};

export default BatchState;
