import React, { useState, useEffect } from "react";
import email from "../../../../images/icons/email.png";
import no_email from "../../../../images/icons/no_email.png";
import call from "../../../../utils/call";
import RecipientPopup from "./RecipientPopup";

const apiURL = process.env.REACT_APP_EMAILER_SERVER;

const RecipientStats = () => {
  const [recipients, set_recipients] = useState([]);
  const [recipient, set_recipient] = useState(null);
  const [search, set_search] = useState("");
  const [page, set_page] = useState(0);
  const [total_pages, set_total_pages] = useState(0);

  const get_recipients = async (page) => {
    const { meta, recipients } = await call(
      `${apiURL}api/v2/recipient/${search}${page ? `?page=${page}` : ""}`,
      "get"
    );
    set_recipients(recipients);
    set_total_pages(meta.total_pages);
    set_page(meta.page - 1);
  };

  const get_recipient = async (id) => {
    const rec = await call(`${apiURL}api/v2/recipient/id/${id}`, "get");

    set_recipient(rec);
  };

  const update_status = async (id) => {
    try {
      const res = await call(`${apiURL}api/v2/recipient/${id}`, "patch");
      console.log(res);

      set_recipients(
        recipients.map((recipient) => {
          if (recipient.id === id) {
            return { ...recipient, no_contact: !recipient.no_contact };
          }
          return recipient;
        })
      );
    } catch (error) {}
  };

  useEffect(() => {
    get_recipients();
  }, []);

  useEffect(() => {
    console.log(page);
  }, [page]);

  return (
    <div className="recipient_stats">
      <form className="recipient_stats__search">
        <input
          type="text"
          placeholder="Search for a Recipient"
          value={search}
          onChange={(e) => set_search(e.target.value)}
        />
        <button
          className="recipient_stats__search__button"
          onClick={(e) => {
            e.preventDefault();
            get_recipients();
          }}
        >
          Search
        </button>
      </form>
      <table className="dashboard__emailer__table" cellSpacing={0}>
        <thead>
          <tr>
            <th>Recipient</th>
            <th>Total Emails</th>
            <th>Sent Emails</th>
            <th>Unsent Emails</th>
            <th>Resolved/Ignored Emails</th>
            <th>Contact</th>
          </tr>
        </thead>
        <tbody>
          {recipients
            ? recipients.map((recipient) => (
                <tr key={recipient.id}>
                  <td
                    className="recipient_stats__name"
                    onClick={() => get_recipient(recipient.id)}
                  >
                    {recipient.first_name} {recipient.last_name}{" "}
                    {`(${recipient.school})`}
                  </td>
                  <td>{recipient.total}</td>
                  <td>{recipient.sent}</td>
                  <td>{recipient.unsent}</td>
                  <td>{recipient.resolved}</td>
                  <td
                    className="recipient_stats__contact"
                    onClick={() => update_status(recipient.id)}
                  >
                    {recipient.no_contact ? (
                      <img src={no_email} />
                    ) : (
                      <img src={email} />
                    )}
                  </td>
                </tr>
              ))
            : ""}
        </tbody>
      </table>
      {total_pages > 0 ? (
        total_pages < 11 ? (
          <div className="dashboard__emailer__pagination">
            {Array(total_pages)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className={`dashboard__emailer__pagination__page ${
                    index === page
                      ? "dashboard__emailer__pagination__page__active"
                      : ""
                  }`}
                  onClick={() => get_recipients(index + 1)}
                >
                  {index + 1}
                </div>
              ))}
          </div>
        ) : (
          <div className="dashboard__emailer__pagination">
            {/* Start */}
            {page < 4
              ? Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        page === index
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() => get_recipients(index + 1)}
                    >
                      {index + 1}
                    </div>
                  ))
              : Array(2)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        page === index
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() => get_recipients(index + 1)}
                    >
                      {index + 1}
                    </div>
                  ))}
            {/* Middle */}
            <div className={`dashboard__emailer__pagination__page`}>...</div>
            {page > 3 && page < total_pages - 3
              ? Array(3)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        page === index + page - 1
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() => get_recipients(index + page)}
                    >
                      {index + page}
                    </div>
                  ))
              : ""}
            {page > 3 && page < total_pages - 3 ? (
              <div className={`dashboard__emailer__pagination__page`}>...</div>
            ) : (
              ""
            )}

            {/* End */}
            {page > total_pages - 4
              ? Array(4)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        page === index + total_pages - 4
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() => get_recipients(index + total_pages - 3)}
                    >
                      {index + total_pages - 3}
                    </div>
                  ))
              : Array(2)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        page === index + total_pages - 1
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() => get_recipients(index + total_pages - 1)}
                    >
                      {index + total_pages - 1}
                    </div>
                  ))}
          </div>
        )
      ) : (
        ""
      )}
      {recipient ? (
        <RecipientPopup
          recipient={recipient}
          set_recipient={set_recipient}
          recipients={recipients}
          set_recipients={set_recipients}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default RecipientStats;
