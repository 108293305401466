import React, { useContext } from "react";
import Emailer from "./Emailer/EMailer";
import RightNav from "../../Nav/RightNav";
import refresh from "../../../images/icons/refresh.png";
import skull from "../../../images/icons/skull.png";
import { BatchContext } from "../../../context/batch/BatchContext";
import RecipientStats from "./RecipientStats/RecipientStats";
import Queue from "./Queue/Queue";
import call from "../../../utils/call";
const apiURL = process.env.REACT_APP_EMAILER_SERVER;

const Container = ({ page }) => {
  const batchContext = useContext(BatchContext);

  const rnav_content = {
    "E-Mailer": {
      options: [
        {
          name: "Refresh Batches",
          image: refresh,
          onClick: () => batchContext.getBatches(),
        },
      ],
      info: [
        `Refresh~ the batches to see the latest changes.`,
        "Email Options~:",
        `Resolve~: This will mark all emails as resolved. It will not send emails, and they will be unable to send in the future.`,
      ],
    },
    "Recipient Statistics": {
      options: [
        {
          name: "Fetch Again",
          image: refresh,
          onClick: () => console.log("clicked"),
        },
      ],
      info: [
        `Search~ for a recipient by name or email to see their email history.`,
      ],
    },
    Queue: {
      options: [
        {
          name: "Kill Queues",
          image: skull,
          onClick: () => call(`${apiURL}api/v2/queue`, "delete"),
        },
      ],
      info: [
        `Refresh~ the batches to see the latest changes.`,
        "Email Options~:",
        `Resolve~: This will mark all emails as resolved. It will not send emails, and they will be unable to send in the future.`,
      ],
    },
  };
  return (
    <div className="dashboard__container">
      <div className="dashboard__container__main_box">
        <div className="dashboard__container__main_box__header">
          <h2 className="dashboard__container__main_box__header__title">
            {page}
          </h2>
        </div>
        {page === "E-Mailer" ? (
          <Emailer />
        ) : page === "Recipient Statistics" ? (
          <RecipientStats />
        ) : page === "Queue" ? (
          <Queue />
        ) : null}
      </div>

      <RightNav rnav_content={rnav_content[page]} />
    </div>
  );
};

export default Container;
