import React, { useContext, useEffect, useRef, useState } from "react";
import mail from "../../../../images/icons/mail.png";
import folder from "../../../../images/icons/folder.png";
import fe_gif from "../../../../images/logo/fe_gif.gif";
import { BatchContext } from "../../../../context/batch/BatchContext";
import call from "../../../../utils/call";

const apiURL = process.env.REACT_APP_EMAILER_SERVER;

const Queue = () => {
  const [tab, set_tab] = useState("Unsent Emails");
  const [batches, set_batches] = useState([]);
  const [batch, set_batch] = useState(null);
  const [loading, set_loading] = useState(false);
  const [queued_batches, set_queued_batches] = useState([]);
  const batchContext = useContext(BatchContext);
  const timer_set = useRef(false);
  const first_load = useRef(true);

  const get_batches = async (page = 0) => {
    set_batches([]);
    const resolved = tab === "Sent Emails";
    set_loading(true);
    await batchContext.getBatches(resolved, page);
    set_loading(false);
  };

  const batch_update = async () => {
    await get_batches();
    const queue = await call(`${apiURL}api/v2/queue`, "get");
    set_queued_batches(queue);
  };

  useEffect(() => {
    batch_update();
    // eslint-disable-next-line
    if (!timer_set.current) {
      timer_set.current = true;
      timer_set.intervalId = setInterval(batch_update, 5000);
    }

    return () => {
      if (first_load.current) {
        first_load.current = false;
        return;
      }
      console.log("clearing interval");
      clearInterval(timer_set.intervalId);
    };
  }, []);

  useEffect(() => {
    get_batches();
  }, [tab]);

  useEffect(() => {
    set_batches(batchContext.batches);
  }, [batchContext.batches]);

  const queueBatch = async (batch_id) => {
    console.log("queing batch", batch_id);
    if (batch_id) {
      await call(`${apiURL}api/v2/queue`, "post", {
        batch_id,
      });

      const queue = await call(`${apiURL}api/v2/queue`, "get");
      set_queued_batches(queue);
    }
  };

  useEffect(() => console.log(queued_batches), [queued_batches]);

  return (
    <div className="dashboard__emailer">
      <div className="dashboard__emailer__body" style={{ padding: "10px" }}>
        {loading ? (
          <div className="dashboard__emailer__loading">
            <img src={fe_gif} />
          </div>
        ) : (
          ""
        )}
        <h3>Unsent Batches</h3>
        <table className="dashboard__emailer__table" cellSpacing={0}>
          <thead>
            <tr>
              <th>Batch Name</th>
              <th># of Pending Emails</th>
              {tab === "Unsent Emails" ? <th>Options</th> : ""}
            </tr>
          </thead>
          <tbody>
            {batches.map((batch, index) => (
              <tr key={index}>
                <td
                  className="dashboard__emailer__table__batch_name"
                  onClick={() => set_batch(batch)}
                >
                  {batch.name}
                </td>
                <td>{batch.pending}</td>
                {queued_batches.find((b) => b.id === batch.id) ? (
                  <td>Already Queued</td>
                ) : (
                  <td
                    className="dashboard__emailer__table__batch_name"
                    onClick={() => {
                      queueBatch(batch.id);
                    }}
                  >
                    Add to Queue
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>

        {batchContext.page && batchContext.total_pages < 11 ? (
          <div className="dashboard__emailer__pagination">
            {Array(batchContext.total_pages)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className={`dashboard__emailer__pagination__page ${
                    batchContext.page - 1 === index
                      ? "dashboard__emailer__pagination__page__active"
                      : ""
                  }`}
                  onClick={() => get_batches(index)}
                >
                  {index + 1}
                </div>
              ))}
          </div>
        ) : (
          <div className="dashboard__emailer__pagination">
            {/* Start */}
            {batchContext.page < 5
              ? Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        batchContext.page - 1 === index
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() => get_batches(index)}
                    >
                      {index + 1}
                    </div>
                  ))
              : Array(2)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        batchContext.page === index
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() => get_batches(index)}
                    >
                      {index + 1}
                    </div>
                  ))}
            {/* Middle */}
            <div className={`dashboard__emailer__pagination__page`}>...</div>
            {batchContext.page > 4 &&
            batchContext.page < batchContext.total_pages - 3
              ? Array(3)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        batchContext.page === index + batchContext.page - 1
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() => get_batches(index + batchContext.page - 2)}
                    >
                      {index + batchContext.page - 1}
                    </div>
                  ))
              : ""}
            {batchContext.page > 4 &&
            batchContext.page < batchContext.total_pages - 3 ? (
              <div className={`dashboard__emailer__pagination__page`}>...</div>
            ) : (
              ""
            )}

            {/* End */}
            {batchContext.page > batchContext.total_pages - 4
              ? Array(4)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        batchContext.page - 1 ===
                        index + batchContext.total_pages - 5
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() =>
                        get_batches(index + batchContext.total_pages - 5)
                      }
                    >
                      {index + batchContext.total_pages - 4}
                    </div>
                  ))
              : Array(2)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        batchContext.page - 1 ===
                        index + batchContext.total_pages - 3
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() =>
                        get_batches(index + batchContext.total_pages - 3)
                      }
                    >
                      {index + batchContext.total_pages - 2}
                    </div>
                  ))}
          </div>
        )}
        {queued_batches.length > 0 ? (
          <div>
            <h3>Queue</h3>
            <table className="dashboard__emailer__table" cellSpacing={0}>
              <thead>
                <tr>
                  <th>Batch Name</th>
                  <th>Status</th>
                  <th># of Emails</th>
                </tr>
              </thead>
              <tbody>
                {queued_batches.map((batch, index) => (
                  <tr key={index}>
                    <td
                      className="dashboard__emailer__table__batch_name"
                      onClick={() => set_batch(batch)}
                    >
                      {batch.name}
                    </td>
                    <td>
                      {batch?.status?.charAt(0)?.toUpperCase()}
                      {batch?.status?.slice(1)}
                    </td>
                    <td>
                      <b>Unsent: </b>
                      {batch.unresolved_emails}
                      <br />
                      <b>Sent: </b>
                      {batch.resolved_emails}
                      <br />
                      <b>Total: </b>
                      {batch.unresolved_emails + batch.resolved_emails}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Queue;
