import React, { useReducer } from "react";
import { AuthContext } from "./AuthContext";
import AuthReducer from "./AuthReducer";
import call from "../../utils/call";
import setAuthToken from "../../utils/set_auth_token";

import {
  USER_LOADED,
  LOAD_FAILED,
  AUTH_ERROR,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CLEAR_ERRORS,
} from "../types.js";

const apiURL = process.env.REACT_APP_EMAILER_SERVER;

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem("token"),
    isAuthenticated: null,
    loading: true,
    user: null,
    error: null,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);
  // Load User
  const loadUser = async () => {
    console.log("load user", localStorage.token);
    try {
      if (localStorage.token) {
        setAuthToken(localStorage.token);
        const res = await call(`${apiURL}api/v1/auth`, "GET");
        if (!res) throw new Error("Could not login");
        dispatch({ type: USER_LOADED, payload: res });
        return true;
      } else {
        dispatch({ type: LOAD_FAILED });
        return false;
      }
    } catch (err) {
      console.log("failed");
      console.error(err);
      dispatch({ type: AUTH_ERROR, payload: err });
    }
  };

  // Register User

  // Login User
  const login = async (formData) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      console.log(apiURL);
      const res = await call(`${apiURL}api/v1/auth`, "post", {
        ...config,
        ...formData,
      });
      clearErrors();
      setAuthToken(res.token);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: res,
      });

      return true;
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.response.data.msg,
      });
      throw err;
    }
  };

  // Logout User
  const logout = () => dispatch({ type: LOGOUT });

  // Change Password
  //   const changePass = async (pass) => {
  //     const config = {
  //       headers: {
  //         "Content-Type": "application/json",
  //       },
  //     };
  //     try {
  //       await axios.put(`${apiURL}api/auth/pass/${state.user._id}`, pass, config);
  //       return true;
  //     } catch (err) {
  //       dispatch({
  //         type: CHANGE_PASS_FAILED,
  //         payload: err.response.data.msg,
  //       });
  //       return false;
  //     }
  //   };

  //   const getReset = async ({ email }) => {
  //     try {
  //       const res = await axios.post(`${apiURL}api/auth/passwordReset`, {
  //         email: email,
  //       });
  //       console.log(res);
  //       return true;
  //     } catch (err) {
  //       console.log(err.response.data.msg);
  //       dispatch({
  //         type: RESET_PASS_FAILED,
  //         payload: err.response.data.msg,
  //       });
  //       return false;
  //     }
  //   };

  //   const resetPassword = async (pass, token) => {
  //     try {
  //       await axios.put(
  //         `${apiURL}api/auth/passwordReset`,
  //         { password: pass },
  //         {
  //           headers: {
  //             "Content-Type": "application/json",
  //             "x-auth-token": token,
  //           },
  //         }
  //       );
  //       return true;
  //     } catch (err) {
  //       dispatch({
  //         type: CHANGE_PASS_FAILED,
  //         payload: err.response.data.msg,
  //       });
  //       return false;
  //     }
  //   };

  // Clear Errors
  const clearErrors = () => dispatch({ type: CLEAR_ERRORS });

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        error: state.error,
        user: state.user,
        loadUser,
        login,
        logout,
        clearErrors,
        // changePass,
        // getReset,
        // resetPassword,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
