import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/auth/AuthContext";
import Header from "../Header/Header";

const Login = () => {
  const authContext = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async () => {
    await authContext.login({
      email,
      password,
    });
  };

  return (
    <div className="login">
      <Header />
      <div className="login__window">
        <div className="login__window__logo"></div>
        <div className="login__window__form">
          <label className="login__window__form__label" htmlFor="email">
            Email:
          </label>
          <input
            className="login__window__form__input"
            type="email"
            name="email"
            id="email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />
          <label className="login__window__form__label" htmlFor="password">
            Password:
          </label>
          <input
            className="login__window__form__input"
            type="password"
            name="password"
            id="password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
          <div
            className="login__window__form__button"
            onClick={() => {
              console.log("submitted");
              login();
            }}
          >
            Login
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
