import React, { useContext, useEffect, useState } from "react";
import ButtonMenu from "../ButtonMenu";
import mail from "../../../../images/icons/mail.png";
import folder from "../../../../images/icons/folder.png";
import check from "../../../../images/icons/check.png";
import mute from "../../../../images/icons/mute.png";
import send from "../../../../images/icons/send.png";
import delete_item from "../../../../images/icons/delete.png";
import fe_gif from "../../../../images/logo/fe_gif.gif";
import { BatchContext } from "../../../../context/batch/BatchContext";
import EmailPopup from "./EmailPopup";
import call from "../../../../utils/call";

const apiURL = process.env.REACT_APP_EMAILER_SERVER;

const EMailer = () => {
  const [tab, set_tab] = useState("Unsent Emails");
  const [batches, set_batches] = useState([]);
  const [batch, set_batch] = useState(null);
  const [auto_send, set_auto_send] = useState(false);
  const [loading, set_loading] = useState(false);
  const batchContext = useContext(BatchContext);

  const buttons = [
    {
      name: "Unsent Emails",
      image: mail,
    },
    {
      name: "Sent Emails",
      image: folder,
    },
  ];

  const get_batches = async (page = 0) => {
    set_batches([]);
    const resolved = tab === "Sent Emails";
    set_loading(true);
    await batchContext.getBatches(resolved, page);
    set_loading(false);
  };

  const update_batches = async (batch_id, status) => {
    if (status) {
      await call(`${apiURL}api/v1/batch/emails/${batch_id}`, "patch", {
        action: status,
      });

      get_batches();
    }
  };

  const delete_batches = async (batch_id) => {
    if (batch_id) {
      await call(`${apiURL}api/v1/batch/${batch_id}`, "delete");

      get_batches();
    }
  };

  useEffect(() => {
    get_batches();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    get_batches();
  }, [tab]);

  useEffect(() => {
    set_batches(batchContext.batches);
  }, [batchContext.batches]);

  return (
    <div className="dashboard__emailer">
      <div className="dashboard__emailer__body">
        <ButtonMenu buttons={buttons} set_tab={set_tab} tab={tab} />
        {loading ? (
          <div className="dashboard__emailer__loading">
            <img src={fe_gif} />
          </div>
        ) : (
          ""
        )}
        <table className="dashboard__emailer__table" cellSpacing={0}>
          <thead>
            <tr>
              <th>Batch Name</th>
              <th># of Pending Emails</th>
              {tab === "Unsent Emails" ? <th>Options</th> : ""}
            </tr>
          </thead>
          <tbody>
            {batches.map((batch, index) => (
              <tr key={index}>
                <td
                  className="dashboard__emailer__table__batch_name"
                  onClick={() => set_batch(batch)}
                >
                  {batch.name}
                </td>
                <td>{batch.pending}</td>
                {tab === "Unsent Emails" ? (
                  <td>
                    {/* <img
                      src={send}
                      alt="Send"
                      title="Send"
                      className="dashboard__emailer__table__option_image"
                      onClick={() => {
                        set_auto_send(true);
                        set_batch(batch);
                      }}
                    /> */}
                    <img
                      src={check}
                      alt="Resolve"
                      title="Resolve"
                      className="dashboard__emailer__table__option_image"
                      onClick={() => update_batches(batch.id, "resolve")}
                    />
                    <img
                      src={mute}
                      alt="Ignore"
                      title="Ignore"
                      className="dashboard__emailer__table__option_image"
                      onClick={() => update_batches(batch.id, "ignore")}
                    />
                    <img
                      src={delete_item}
                      alt="Delete"
                      title="Delete"
                      className="dashboard__emailer__table__option_image"
                      onClick={() => delete_batches(batch.id)}
                    />
                  </td>
                ) : (
                  ""
                )}
              </tr>
            ))}
          </tbody>
        </table>

        {batchContext.page && batchContext.total_pages < 11 ? (
          <div className="dashboard__emailer__pagination">
            {Array(batchContext.total_pages)
              .fill(0)
              .map((_, index) => (
                <div
                  key={index}
                  className={`dashboard__emailer__pagination__page ${
                    batchContext.page - 1 === index
                      ? "dashboard__emailer__pagination__page__active"
                      : ""
                  }`}
                  onClick={() => get_batches(index)}
                >
                  {index + 1}
                </div>
              ))}
          </div>
        ) : (
          <div className="dashboard__emailer__pagination">
            {/* Start */}
            {batchContext.page < 5
              ? Array(5)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        batchContext.page - 1 === index
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() => get_batches(index)}
                    >
                      {index + 1}
                    </div>
                  ))
              : Array(2)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        batchContext.page === index
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() => get_batches(index)}
                    >
                      {index + 1}
                    </div>
                  ))}
            {/* Middle */}
            <div className={`dashboard__emailer__pagination__page`}>...</div>
            {batchContext.page > 4 &&
            batchContext.page < batchContext.total_pages - 3
              ? Array(3)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        batchContext.page === index + batchContext.page - 1
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() => get_batches(index + batchContext.page - 2)}
                    >
                      {index + batchContext.page - 1}
                    </div>
                  ))
              : ""}
            {batchContext.page > 4 &&
            batchContext.page < batchContext.total_pages - 3 ? (
              <div className={`dashboard__emailer__pagination__page`}>...</div>
            ) : (
              ""
            )}

            {/* End */}
            {batchContext.page > batchContext.total_pages - 4
              ? Array(4)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        batchContext.page - 1 ===
                        index + batchContext.total_pages - 5
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() =>
                        get_batches(index + batchContext.total_pages - 5)
                      }
                    >
                      {index + batchContext.total_pages - 4}
                    </div>
                  ))
              : Array(2)
                  .fill(0)
                  .map((_, index) => (
                    <div
                      key={index}
                      className={`dashboard__emailer__pagination__page ${
                        batchContext.page - 1 ===
                        index + batchContext.total_pages - 3
                          ? "dashboard__emailer__pagination__page__active"
                          : ""
                      }`}
                      onClick={() =>
                        get_batches(index + batchContext.total_pages - 3)
                      }
                    >
                      {index + batchContext.total_pages - 2}
                    </div>
                  ))}
          </div>
        )}
      </div>
      {batch ? (
        <EmailPopup batch={batch} set_batch={set_batch} auto_send={auto_send} />
      ) : (
        ""
      )}
    </div>
  );
};

export default EMailer;
