import React from "react";
import fe_gif from "../../images/logo/fe_gif.gif";

const Loading = () => {
  return (
    <div className="loading">
      <div className="loading__gif">
        <img src={fe_gif} />
      </div>
      <div className="loading__text">Loading...</div>
    </div>
  );
};

export default Loading;
