import React from "react";

const RightNav = ({ rnav_content }) => {
  return (
    <div className="dashboard__right_nav">
      {rnav_content.options.map((option, index) => (
        <div
          key={index}
          className="dashboard__right_nav__option"
          onClick={() => option.onClick()}
        >
          <img
            src={option.image}
            alt={option.name}
            className="dashboard__right_nav__option__image"
          />
          <span className="dashboard__right_nav__option__text">
            {option.name}
          </span>
        </div>
      ))}
      <div className="dashboard__right_nav__info">
        {rnav_content.info.map((line, index) => (
          <p className="dashboard__right_nav__info__text" key={index}>
            {line.split("~").map((fragment, index) => (
              <span key={index}>
                {index === 0 ? <b>{fragment}</b> : fragment}
              </span>
            ))}
          </p>
        ))}
      </div>
    </div>
  );
};

export default RightNav;
