import React from "react";

const ButtonMenu = ({ buttons, tab, set_tab }) => {
  return (
    <div className="dashboard__button_menu">
      {buttons.map((button, index) => (
        <div
          key={index}
          className={`dashboard__button_menu__button ${
            tab === button.name ? "dashboard__button_menu__button--active" : ""
          }`}
          onClick={() => set_tab(button.name)}
        >
          <img
            src={button.image}
            alt={button.name}
            className="dashboard__button_menu__button__image"
          />
          <p className="dashboard__button_menu__button__text">{button.name}</p>
        </div>
      ))}
    </div>
  );
};

export default ButtonMenu;
