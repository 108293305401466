import React, { useState } from "react";
import call from "../../../../utils/call";
import email_icon from "../../../../images/icons/email.png";
import no_email from "../../../../images/icons/no_email.png";
import Parser from "html-react-parser";
import check from "../../../../images/icons/check.png";
import mute from "../../../../images/icons/mute.png";

const apiURL = process.env.REACT_APP_EMAILER_SERVER;

const RecipientPopup = ({
  recipient,
  set_recipient,
  recipients,
  set_recipients,
}) => {
  const [email, set_email] = useState(null);

  const update_status = async () => {
    try {
      const res = await call(
        `${apiURL}api/v2/recipient/${recipient.id}`,
        "patch"
      );
      console.log(res);

      set_recipient({ ...recipient, no_contact: !recipient.no_contact });

      set_recipients(
        recipients.map((r) => {
          if (r.id === recipient.id) {
            return { ...r, no_contact: !r.no_contact };
          }
          return r;
        })
      );
    } catch (error) {}
  };

  const get_email = async (id) => {
    console.log(id);
    const { body } = await call(`${apiURL}api/v2/email/id/${id}`, "get");
    set_email(body);
  };

  const update_email_status = async (status, id) => {
    console.log(status);
    try {
      await call(`${apiURL}api/v2/email/${id}`, "patch", {
        status,
      });

      const updated_emails = recipient.emails.map((e) => {
        if (e.id === id) {
          return { ...e, [status]: true };
        }
        return e;
      });

      set_recipient({ ...recipient, emails: updated_emails });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="email_popup">
      <div
        className="email_popup__overlay"
        onClick={() => set_recipient(null)}
      ></div>
      <div className="email_popup__content">
        <div className="email_popup__content__header">
          <h2 className="email_popup__content__header__title">
            {recipient.first_name} {recipient.last_name} ({recipient.school})
          </h2>
        </div>

        <div className="email_popup__content__body">
          <div
            className="recipient_popup__status"
            onClick={() => update_status()}
          >
            {recipient.no_contact ? (
              <img src={no_email} alt="no email" />
            ) : (
              <img src={email_icon} alt="email" />
            )}
          </div>
          {email ? (
            <div className="email_popup__content__body__recipient_email">
              {Parser(email)}
            </div>
          ) : (
            ""
          )}
          <table className="dashboard__emailer__table">
            <thead>
              <tr>
                <th>Subject</th>
                <th>Status</th>
                <th>Date Generated</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {recipient.emails
                .sort((e1, e2) => {
                  return new Date(e2.createdAt) - new Date(e1.createdAt);
                })
                .map((e) => (
                  <tr key={e.id}>
                    <td
                      onClick={() => get_email(e.id)}
                      className="recipient_stats__name"
                    >
                      {e.subject}
                    </td>
                    <td>
                      {e.sent
                        ? "Sent"
                        : e.ignored
                        ? "Ignored"
                        : e.resolved
                        ? "Resolved"
                        : "Not Sent"}
                    </td>
                    <td>{e.createdAt}</td>
                    <td>
                      {!e.sent && !e.ignored && !e.resolved ? (
                        <div className="recipient_stats__actions">
                          <img
                            src={check}
                            alt="resolve"
                            title="Resolve"
                            onClick={() => {
                              update_email_status("resolved", e.id);
                            }}
                          />
                          <img
                            src={mute}
                            alt="ignore"
                            title="Ignore"
                            onClick={() => {
                              update_email_status("ignored", e.id);
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default RecipientPopup;
