import React, { useContext, useState } from "react";
import Header from "../Header/Header";
import LeftNav from "../Nav/LeftNav";
import Container from "./components/Container";
import { AuthContext } from "../../context/auth/AuthContext";

const Dashboard = () => {
  const authContext = useContext(AuthContext);

  const [left_nav, set_left_nav] = useState(true);
  const [page, set_page] = useState("E-Mailer");

  return authContext.isAuthenticated ? (
    <div className="dashboard">
      <Header />
      <LeftNav
        left_nav={left_nav}
        set_page={set_page}
        set_left_nav={set_left_nav}
      />
      <div className="dashboard__spacer"></div>
      <Container page={page} />
    </div>
  ) : (
    ""
  );
};

export default Dashboard;
