import "./styles/App.scss";
import Main from "./components/Main/Main";
import AuthState from "./context/auth/AuthState";
import BatchState from "./context/batch/BatchState";

function App() {
  return (
    <AuthState>
      <BatchState>
        <Main />
      </BatchState>
    </AuthState>
  );
}

export default App;
