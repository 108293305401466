import { GET_BATCHES, LOADING, CLEAR_BATCHES, FAILED } from "../types";

const Reducer = (state, action) => {
  switch (action.type) {
    case GET_BATCHES:
      return {
        ...state,
        batches: action.payload.batches,
        loading: false,
        page: action.payload.meta.page,
        total_pages: action.payload.meta.total_pages,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case CLEAR_BATCHES:
      return {
        ...state,
        batches: [],
        loading: false,
        page: 0,
        total_pages: 0,
      };
    case FAILED:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default Reducer;
