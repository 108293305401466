import React, { useState, useContext, useEffect } from "react";
import Login from "../Login/Login";
import { AuthContext } from "../../context/auth/AuthContext";
import Dashboard from "../Dashboard/Dashboard";
import Loading from "./Loading";

const Main = () => {
  const authContext = useContext(AuthContext);

  console.log(authContext.isAuthenticated);

  const [loading, setLoading] = useState(true);

  const authenticate = async () => {
    await authContext.loadUser();
    setLoading(false);
  };

  useEffect(() => {
    authenticate();
  }, []);

  return (
    <div className="emailer">
      {loading ? (
        <Loading />
      ) : authContext.isAuthenticated ? (
        <Dashboard />
      ) : (
        <Login />
      )}
    </div>
  );
};

export default Main;
