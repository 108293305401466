import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "../../context/auth/AuthContext";
import logout from "../../images/svg/logout.svg";

const Header = () => {
  const [user_menu, set_user_menu] = useState(false);
  const authContext = useContext(AuthContext);

  useEffect(() => {
    if (authContext.isAuthenticated) authContext.loadUser();
  }, [authContext.isAuthenticated]);

  return (
    <div className="login__header">
      <div className="login__header__logo"></div>
      {authContext.isAuthenticated ? (
        <div
          className="login__header__avatar"
          onClick={() => {
            set_user_menu(!user_menu);
          }}
        >
          {authContext.user ? (
            <div className="login__header__avatar__user">
              {authContext.user.email[0].toUpperCase()}
              {user_menu ? (
                <div className="login__header__avatar__user__menu">
                  <div
                    className="login__header__avatar__user__menu__item"
                    onClick={() => {
                      authContext.logout();
                    }}
                  >
                    <img
                      src={logout}
                      alt="logout"
                      className="login__header__avatar__user__menu__item__icon"
                    />
                    <span className="login__header__avatar__user__menu__item__text">
                      Logout
                    </span>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default Header;
