import axios from "axios";

const call = async (url, method, data) => {
  try {
    const response = await axios({
      url,
      method,
      data,
    });

    return response.data;
  } catch (error) {
    console.error(error);
  }
};

export default call;
